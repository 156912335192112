import { graphql, useStaticQuery } from "gatsby"
import "../stylesheets/general.css"
import "../stylesheets/contact.css"
import React from "react"
import { Card, ContactForm, Content, Hero, Layout } from "../components"
import LocationCard from "../components/LocationCard"
import ReachOutCard from "../components/ReachOutCard"
import { FullWidthContainer, H1 } from "../elements"
import SEO from "../components/SEO"

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: imageSharp(
        fluid: { originalName: { eq: "contacthero2.jpg" } }
      ) {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title={"Contact"} description={"Let’s talk about your transformation now."} />
      <Hero
        title="We’re Here For You. Say Hello."
        image={data.heroImage.fluid}
        buttonTitle="Hello from all of us to you"
        description="Let’s talk about your transformation now."
      />
      <Content spacing>
        <Card name="Reach Out." margin="0 0 82px 0"  grid="2x2">
          <ReachOutCard 
          title="Become a client"
          description="If you want to start a new relationship with us email: <a href='mailto:sales@itspixelperfect.com'>sales@itspixelperfect.com</a>."
          />
          <ReachOutCard 
          title="Joining our team"
          description="Looking for an open positions check out our careers page or email us at <a href='mailto:careers@itspixelperfect.com'>careers@itspixelperfect.com</a>."
          />
          <ReachOutCard 
          title="Everything Else"
          description="For general and media queries, email us at <a href='mailto:hello@itspixelperfect.com'>hello@itspixelperfect.com</a> or call <a href='tel:8769705529'>+1 876 970-5529</a>."
          />
          <ReachOutCard 
          title="Address"
          description="Suites 22 & 23,</br> Technology Innovation Centre University of Technology 237 Old Hope Road,</br> Kingston 6, Jamaica W.I."
          />
        </Card>
      </Content>
      <FullWidthContainer>
        <Content>
            <H1 id="sendus" margin="0 0 70px 0" large>
            Send Us a Message
          </H1>
          <ContactForm />
        </Content>
      </FullWidthContainer>

    </Layout>
  )
}
export default ContactPage
